<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Base Price Profile</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>
      <div class="mb-3">
        <label for="nama" class="form-label">Nama</label>
        <bs-plain-text v-model="model.nama"/>
      </div>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Tipe Waktu</label>
        <bs-plain-text v-model="model.timeRangeNama"/>
      </div>

      <div class="mb-3">
        <label for="startDay" class="form-label">Hari Mulai</label>
        <bs-plain-text v-model="model.startDayName"/>
      </div>

      <div class="mb-3">
        <label for="location" class="form-label">Lokasi</label>
        <bs-plain-text v-model="model.locationCode"/>
      </div>

      <div class="mb-3">
        <label for="currency" class="form-label">Mata Uang</label>
        <bs-plain-text v-model="model.currencyCode"/>
      </div>

      <div class="mb-3">
        <label for="unit" class="form-label">Satuan Unit</label>
        <bs-plain-text v-model="model.unitCode"/>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="editData"><i class="bi bi-pencil-square"></i>
        Edit
      </button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import BasePriceProfileService from "@/services/base-price-profile-service";
// import SecurityService from "@/services/security-service.js";

export default {
  data: function () {
    return {
      id: null,
      model: BasePriceProfileService.default(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.id = id;
    this.loadData();
  },
  methods: {
    async loadData() {
      let response = await BasePriceProfileService.getObject(this.id);
      this.model = response.data;
      if (response.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = response.errorMessages;
      }
    },
    onPopupClosed() {
      this.$router.push('/base-price-profile');
    },
    close() {
      this.$refs.form.closePopup();
    },
    editData() {
      this.$refs.form.closePopup();
      this.$router.push(`/base-price-profile/edit/${this.id}`);
    }
  }
}
</script>